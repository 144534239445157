<template>
  <div id="scroll-target" class="overflow-y-auto" >
    <div v-scroll:#scroll-target="onScroll">
    <headerPage :elevation="scroll > 0 ? 3 : 0" v-bind:scroll="scroll" class="headerPage" />
    <router-view ></router-view>
    <footerPage />
    </div>
  </div>
</template>
<script>
const headerPage = () => import("../views/header.vue");
const footerPage = () => import("../views/footer.vue");
// const homeContent = () => import("../views/homecontent.vue");
export default {
  components: {
    headerPage,
    footerPage,
    // homeContent,
  },
  data() {
    return {
      showModal: false,
      zebuLink: "https://v2.zebull.in/#/",
      companies: [{ title: "About" }, { title: "Blog" }, { title: "Careers" }],
      products: [
        { title: "Publisher" },
        { title: "Share" },
        { title: "Brokers" },
      ],
      scroll: 0,
    };
  },
  methods: {
    modelShow() {
      this.showModal = !this.showModal;
    },
    callZebuLink(broker) {
      if(broker == 'zebu') {
      window.open(
        'https://v2.zebull.in/#/?mode=SSO&vendor=Amoga&redirectUrl=https://google.co.in','_blank'
      );
      }
      else if (broker == "bnr") {
        window.open(
        'https://s.bnrsecurities.com/#/?mode=SSO&vendor=Amoga&redirectUrl=https://google.co.in','_blank'
      );
      }
    },
    onScroll (e) {
        this.scroll = e.target.scrollTop
        console.log(this.scroll)
      },
  },
  computedId: {
    scroll() {
      return { ...this.$attrs };
    },
  },
  mounted() {
    // localStorage.clear();
  }
};
</script>
